import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { default as DefaultContainer } from '@/browser/Container';
import GreyBox from '@/browser/GreyBox';
import PriceBall from '@/components/Price/PriceBall';
import { StyledButton } from '@/components/Buttons/Button';
import WpImage from '@/components/Images/WpImage';
import Link from '@/components/Link/Link';
import asterisk from './Asterisk.svg';
import Content from '@/browser/Content';

const Section = styled.div`
  // padding: 64px 0;
`;

const Box = styled(GreyBox)`
  border-color: ${(props) => props.theme.colors.kuluttaja2021.army[20]};
  ${(props) => props.theme.media.tablet`
    display: block;
  `}
  ${(props) =>
    !props.$hasProducts &&
    `
    display: grid;
    grid-template-columns: 48% 48%;
    grid-gap: 4%;
    align-items: center;
    padding-top: 64px;
    padding-bottom: 64px;
    h2 {
      font-size: 32px;
    }
  `};
`;

const Header = styled.div`
  ${(props) =>
    !props.$hasProducts &&
    `
    padding-left: 32px;
    padding-right: 32px;
  `}
  ${(props) => props.theme.media.mobile`
    padding-left: 0;
    padding-right: 0;
  `}
  p {
    font-size: 20px;
    font-family: ${(props) => props.theme.fonts.secondary};
  }
  a {
    font-weight: bold;
    color: ${(props) => props.theme.colors.kuluttaja2021.tummaPetroli.full};
  }
`;

const Container = styled(DefaultContainer)`
  background-color: ${(props) =>
    props.theme.colors.kuluttaja2021.tummaPetroli[5]};
  ${(props) => props.$hasProducts && 'text-align: center;'};
  position: relative;
  z-index: 0;
  margin-bottom: 64px;
  ${(props) => props.theme.media.tablet`
    margin-top: 22px;
  `}
`;

const Products = styled.div`
  margin-top: 24px;
  display: grid;
  grid-template-columns: repeat(2, 49%);
  column-gap: 2%;
  row-gap: 22px;
  & > * {
    background-color: white;
  }
  ${(props) => props.theme.media.mobile`
    grid-template-columns: 100%;
  `};
`;

const Login = styled.div`
  display: grid;
  grid-template-columns: 48% 48%;
  grid-gap: 22px 4%;
  ${(props) => props.theme.media.mobile`
    grid-template-columns: 100%;
  `}
`;

const LoginCard = styled.div`
  padding: 30px;
  background-color: #fff;
  text-align: center;
  display: flex;
  flex-direction: column;
  h2 {
    flex: 1;
    align-content: center;
  }
`;

const ProductColumn = styled.div`
  padding: 36px;
  ${(props) => props.theme.media.mobile`
    padding: 36px 8px;
  `};
`;

const ImageContainer = styled.div`
  width: max-content;
  max-width: 100%;
  position: relative;
  margin: 0 auto 22px;
  min-height: 174px;

  ${(props) => props.theme.media.mobile`
    min-height: 94px;
    display: flex;
    align-items: center;
  `};
`;

const ProductImage = styled(WpImage)`
  max-height: 174px;
  margin-left: 120px;

  ${(props) => props.theme.media.mobile`
    max-width: calc(100% - 94px);
    margin-left: 0;
  `};
`;

const ProductPrice = styled.div`
  top: 8px;
  left: 0;
  position: ${(props) => (props.$hasImage ? 'absolute' : 'static')};

  ${(props) => props.theme.media.mobile`
    top: 0;
    left: 16px;
    position: ${(props) => (props.$hasImage ? 'relative' : 'static')};
  `};
`;

const PriceBallDesktop = styled.div`
  ${(props) => props.theme.media.mobile`
    display: none;
  `};
`;

const PriceBallMobile = styled.div`
  display: none;
  ${(props) => props.theme.media.mobile`
    display: block;
  `};
`;

const Description = styled.div`
  margin-bottom: 22px;
`;

const Asterisk = styled.img`
  filter: invert(1);
  opacity: 0.1;
  width: 150px;
  position: absolute;
  top: 15px;
  left: 0;
  z-index: -1;
`;

/**
 * Reorder products by price
 *
 * The array products contains single product objects with price property.
 * The price is in string format, but is actually a float.
 *
 * @param {Array} products
 * @returns
 */
function reOrderProductsByPrice(products) {
  const productsArrayForSort = [...products];
  return productsArrayForSort.sort(
    (a, b) => parseFloat(a.price) - parseFloat(b.price)
  );
}

/**
 * Paywall component for handling restricted content.
 * @param {Object} props - Component props.
 * @returns {JSX.Element} Paywall component.
 */
const Paywall = ({ accessible, products, logged_in }) => {
  if (accessible) {
    return null;
  }

  /**
   * Reorder products by price: from lowest to highest
   *
   * By default all one-time products and subscription products are ordered by
   * product creation day. Per client's request, the products should be ordered
   * by price from lowest to highest.
   */
  products = reOrderProductsByPrice(products);

  const hasProducts = Array.isArray(products) && products.length > 0;
  return (
    <Section>
      <Container $hasProducts={hasProducts}>
        <Asterisk src={asterisk} alt="Maksullista sisältöä" />
        <Box $hasProducts={hasProducts}>
          <Header $hasProducts={hasProducts}>
            <FormattedMessage
              id={hasProducts ? 'paywall.title' : 'paywall.noProducts.title'}
              tagName="h2"
            />
            <FormattedMessage
              id={
                logged_in
                  ? 'paywall.description.isLoggedin'
                  : hasProducts
                    ? 'paywall.description'
                    : 'paywall.noProducts.description'
              }
              tagName="p"
            />
            {hasProducts && !logged_in && (
              <Link to="/käyttäjä/kirjaudu">
                <FormattedMessage id="paywall.loginText" />
              </Link>
            )}
          </Header>
          {hasProducts ? (
            <Products>
              {products.map((product, index) => (
                <ProductColumn key={index}>
                  <h3>{product.title}</h3>
                  <ImageContainer>
                    <ProductPrice $hasImage={product.featuredImage}>
                      <PriceBallDesktop>
                        <PriceBall
                          price={product.price}
                          priceUnit={product.price_unit}
                        />
                      </PriceBallDesktop>
                      <PriceBallMobile>
                        <PriceBall
                          small
                          price={product.price}
                          priceUnit={product.price_unit}
                        />
                      </PriceBallMobile>
                    </ProductPrice>
                    <ProductImage image={product.featuredImage} />
                  </ImageContainer>
                  {product.marketing_text && <h3>{product.marketing_text}</h3>}
                  <Description>
                    <Content
                      html={
                        product.excerpt ? product.excerpt : product.description
                      }
                    />
                  </Description>
                  <StyledButton
                    to={product.uri}
                    $color={index % 2 === 0 ? null : 'kuluttaja2021.tyrni.full'}
                    $txtcolor={
                      index % 2 === 0 ? null : 'kuluttaja2021.harmaa[80]'
                    }
                  >
                    <FormattedMessage id="paywall.purchase" />
                  </StyledButton>
                </ProductColumn>
              ))}
            </Products>
          ) : (
            <Login>
              <LoginCard>
                <FormattedMessage id="paywall.register.title" tagName="h2" />
                <StyledButton $size="small" to="/käyttäjä/rekisteröidy">
                  <FormattedMessage id="paywall.register.cta" />
                </StyledButton>
              </LoginCard>
              <LoginCard>
                <FormattedMessage id="paywall.login.title" tagName="h2" />
                <StyledButton $size="small" to="/käyttäjä/kirjaudu">
                  <FormattedMessage id="paywall.login.cta" />
                </StyledButton>
              </LoginCard>
            </Login>
          )}
        </Box>
      </Container>
    </Section>
  );
};

Paywall.propTypes = {
  accessible: PropTypes.bool,
  products: PropTypes.array,
  logged_in: PropTypes.bool
};

Paywall.defaultProps = {
  accessible: true,
  products: [],
  logged_in: false
};

export default Paywall;
