import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { default as DefaultContainer } from '@/browser/Container';
import { OutsideClickHandler } from '@/redandblue/components';
import Money from '@/browser/Money';
import { default as DefaultLink } from '@/components/Link/Link';
import { StyledButton } from '@/components/Buttons/Button';
import DropdownOpen from '@/components/Buttons/DropdownOpen';
import asterisk from './Asterisk.svg';
import { connect } from 'react-redux';
import { store } from '@/store/reducers/auth';
import RenewSession from '@/containers/Header/UserPanel/RenewSession';
import _get from 'lodash.get';

const Header = styled.header`
  display: flex;
  align-items: center;
  p {
    margin-bottom: 0;
    font-weight: normal;
  }
  ${(props) => props.theme.media.tablet`
    margin-bottom: 22px;
  `}
`;

const Section = styled.div`
  background-color: ${(props) =>
    props.theme.colors.kuluttaja2021.tummaPetroli[60]};
`;

const Container = styled(DefaultContainer)`
  display: grid;
  grid-template-columns: auto max-content;
  column-gap: 2%;
  align-items: center;
  color: #fff;
  padding: 60px 30px;
  ${(props) => props.theme.media.tablet`
    display: block;
  `}
  ${(props) => props.theme.media.mobile`
    padding: 30px 22px;
  `}
`;

const Ctas = styled.div`
  display: flex;
  ${(props) => props.theme.media.mobile`
    flex-direction: column;
  `}
`;

const ProductsDropdown = styled.div`
  position: relative;
  z-index: 1;
  margin-left: 22px;
  ${(props) => props.theme.media.mobile`
    margin-left: 0;
    margin-top: 22px;
  `}
`;

const ProductTitle = styled.span`
  max-width: 320px;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 6px;
`;

const Product = styled.div`
  display: flex;
  text-transform: uppercase;
  align-items: center;
  height: 56px;
`;

const FirstProduct = styled(Product)`
  border: 2px solid #fff;
  ${(props) =>
    props.$hasMultipleProducts &&
    `
    > a {
      width: calc(100% - 56px);
    }
  `}
`;

const Link = styled(DefaultLink)`
  flex: auto;
  padding: 0 20px;
  color: #fff;
  height: 100%;
  display: flex;
  align-items: center;
  word-spacing: 5px;
  &:hover {
    color: #fff;
    background-color: ${(props) =>
      props.theme.colors.kuluttaja2021.tummaPetroli.full};
  }
`;

const ToggleDowndown = styled(DropdownOpen)`
  cursor: pointer;
  width: 52px;
  height: 100%;
  border-color: #fff;
  border-style: solid;
  border-width: 0 0 0 2px;
  background-color: ${(props) =>
    props.$active
      ? props.theme.colors.kuluttaja2021.tummaPetroli.full
      : 'transparent'};
  &:hover {
    border-color: #fff;
  }
  &:after,
  &:before {
    top: 24px;
    width: 10px;
    height: 2px;
    background-color: #fff;
  }
  &:after {
    left: 16px;
  }
  &:before {
    left: 22px;
  }
`;

const DropdownContents = styled.div`
  position: absolute;
  top: 56px;
  width: 100%;
  border-style: solid;
  border-width: 0 2px 2px;
  border-color: #fff;
  background-color: ${(props) =>
    props.theme.colors.kuluttaja2021.tummaPetroli.full};
`;

const Asterisk = styled.img`
  width: 42px;
  height: 42px;
  margin-right: 64px;
  opacity: 0.4;
  ${(props) => props.theme.media.laptop`
    margin-right: 30px;
    `}
`;

/**
 * Reorder products by price
 *
 * The array products contains single product objects with price property.
 * The price is in string format, but is actually a float.
 *
 * @param {Array} products
 * @returns
 */
function reOrderProductsByPrice(products) {
  const productsArrayForSort = [...products];
  return productsArrayForSort.sort(
    (a, b) => parseFloat(a.price) - parseFloat(b.price)
  );
}

class Paywall extends Component {
  static propTypes = {
    accessible: PropTypes.bool,
    restricted: PropTypes.bool,
    products: PropTypes.array
  };
  static defaultProps = {
    accessible: true,
    restricted: false,
    products: []
  };
  state = {
    dropdownActive: false
  };
  toggleActive(toggle) {
    this.setState({ dropdownActive: toggle });
  }
  render() {
    const { accessible, products, usersPlanOnly } = this.props;
    const { user, token } = this.props.auth;
    const { dropdownActive } = this.state;
    console.log('products', products);
    const sortedProducts = reOrderProductsByPrice(products);
    console.log('sortedProducts', sortedProducts);
    const firstProductData = _get(sortedProducts, '[0]');
    if ((accessible && !usersPlanOnly) || (usersPlanOnly && token)) {
      return null;
    }
    if (token && !user) {
      return <RenewSession />;
    }
    return (
      <Section>
        <Container>
          <Header>
            <Asterisk src={asterisk} alt="Maksullista sisältöä" />
            <div>
              <FormattedMessage id="paywall.title.small" tagName="h3" />
              {!token && firstProductData && (
                <FormattedMessage id="paywall.description" tagName="p" />
              )}
              {token && firstProductData && (
                <FormattedMessage
                  id="paywall.description.isLoggedin"
                  tagName="p"
                />
              )}
              {!token && !firstProductData && (
                <FormattedMessage
                  id="paywall.description.notLoggedin"
                  tagName="p"
                />
              )}
            </div>
          </Header>
          <Ctas>
            {!token && (
              <StyledButton
                $color="kuluttaja2021.tyrni[40]"
                $txtcolor="kuluttaja2021.tummaPetroli.full"
                to="/käyttäjä/kirjaudu"
              >
                Kirjaudu sisään
              </StyledButton>
            )}
            {sortedProducts.length > 0 && firstProductData && (
              <OutsideClickHandler
                onOutsideClick={() => this.toggleActive(false)}
              >
                <ProductsDropdown>
                  <FirstProduct $hasMultipleProducts={sortedProducts.length > 1}>
                    <Link to={firstProductData.uri}>
                      <ProductTitle>
                        Osta&nbsp;
                        {firstProductData.title}{' '}
                      </ProductTitle>
                      <Money value={firstProductData.price} />
                    </Link>
                    {sortedProducts.length > 1 && (
                      <ToggleDowndown
                        onClick={() =>
                          this.toggleActive(!this.state.dropdownActive)
                        }
                        $active={dropdownActive}
                      />
                    )}
                  </FirstProduct>
                  {dropdownActive && sortedProducts.length > 1 && (
                    <DropdownContents>
                      {sortedProducts.map(
                        (product, index) =>
                          index > 0 && (
                            <Product key={index}>
                              <Link to={product.uri}>
                                {product.title}&nbsp;
                                <Money value={product.price} />
                              </Link>
                            </Product>
                          )
                      )}
                    </DropdownContents>
                  )}
                </ProductsDropdown>
              </OutsideClickHandler>
            )}
          </Ctas>
        </Container>
      </Section>
    );
  }
}

export default connect((state) => ({ auth: state.auth }), { store })(Paywall);
